import { Colors } from "@constants/colors.constant";
import { Avatar, Button, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { alpha, styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useObservable } from "@ngneat/react-rxjs";
import { Application } from "@store/applications";
import { TwilioAiCall, twilioAiCallsQuery, twilioAiCallsService, TwilioCallStatusEnum } from "@store/twilioAiCalls";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CallChatModal from "../components/applicationDetails/CallChat.modal";
import CallConfirmationModal from "../components/applicationDetails/CallConfirmation.modal";
import { useSnackbar } from "notistack";

const StyledFirstRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: Colors.blue,
  borderRadius: "15px",
  boxShadow: "0 8px 20px 0 rgba(0,0,0,0.1)",
  border: 0,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid rgba(224, 224, 224, 1)",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
}));
interface AdCallHistoryProps {
  search?: string;
  statusFilter?: TwilioCallStatusEnum;
  sortedBy?: ["dateFromRecent" | "dateFromOld" | "name"];
}

function getCurrentName(application: Application) {
  return application.customName || application.candidate?.fullName || application.name;
}

const AdCallHistory = (props: AdCallHistoryProps) => {
  const { statusFilter } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [{ twilioAiCalls }] = useObservable(twilioAiCallsQuery.twilioAiCalls$);
  const [filteredTwilioAiCalls, setFilteredTwilioAiCalls] = useState(
    statusFilter ? twilioAiCalls.filter((call) => statusFilter === call.callStatus) : twilioAiCalls
  );

  const [openCallChat, setOpenCallChat] = useState<TwilioAiCall | null>(null);
  const [openCallConfirmation, setOpenCallConfirmation] = useState<TwilioAiCall | null>(null);
  const [loadingCall, setLoadingCall] = useState(false);

  useEffect(() => {
    setFilteredTwilioAiCalls(statusFilter ? twilioAiCalls.filter((call) => statusFilter === call.callStatus) : twilioAiCalls);
  }, [statusFilter, twilioAiCalls]);

  const downloadCall = (twilioAiCall: TwilioAiCall) => {
    twilioAiCallsService.downloadCall(twilioAiCall.callSid).subscribe({
      next: (callURL) => {
        const link = document.createElement("a");
        link.href = callURL;
        link.target = "_blank";
        link.download = "call_recording.mp3";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    });
  };

  const handleConfirmCall = (application: Application) => {
    setOpenCallConfirmation(null);
    setLoadingCall(true);
    twilioAiCallsService.makeAiCall(application).subscribe({
      next: (twilioAiCall) => {
        twilioAiCallsService.upsertEntities(twilioAiCall);
        twilioAiCallsService.handleSocketListeners([twilioAiCall]);
      },
      error: (err) => {
        setLoadingCall(false);
        enqueueSnackbar(err.text, err.options);
      },
      complete: () => setLoadingCall(false),
    });
  };

  return (
    <Stack sx={{ "& .MuiPaper-root ": { boxShadow: "none", border: 0, backgroundColor: "transparent", padding: "15px" } }}>
      <TableContainer component={Paper} sx={{ "& .MuiPaper-root": { boxShadow: "none", border: 0 }, "& .MuiTable-root": { border: 0 } }}>
        <Table
          sx={{
            "& .MuiTableCell-root": {
              border: 0,
            },
            minWidth: 700,
          }}>
          <TableHead>
            <StyledFirstRow>
              <StyledTableCell>
                {filteredTwilioAiCalls.length > 1 ? (
                  <Typography>{t("ads.details.applyTab.history.applicants")}</Typography>
                ) : (
                  <Typography>{t("ads.details.applyTab.history.applicant")}</Typography>
                )}
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography>{t("global.dateLabel")}</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">{t("global.status")}</StyledTableCell>
              <StyledTableCell align="left"> </StyledTableCell>
              <StyledTableCell align="left"> </StyledTableCell>
            </StyledFirstRow>
          </TableHead>
          <TableBody>
            {filteredTwilioAiCalls.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell colSpan={5} align="center">
                  <Stack minHeight={"40dvh"} justifyContent="center" alignItems="center">
                    <Typography color={Colors.greyLight} variant="h6" sx={{ fontWeight: 500 }}>
                      {t("ads.details.applyTab.history.noData")}
                    </Typography>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              filteredTwilioAiCalls.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    <Stack direction="row" gap="10px" alignItems={"center"} justifyContent={"flex-start"}>
                      <Avatar style={{ width: 36, height: 36 }} src={row.application.candidate?.picture} />
                      {getCurrentName(row.application)}
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {dayjs(row.createdAt).format("DD/MM/YYYY")} {t("global.at")} {dayjs(row.createdAt).format("HH:mm")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Stack direction="row" gap="7px" alignItems={"center"}>
                      <Stack
                        sx={{
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          backgroundColor: Colors.twilioCallsStatus[row.callStatus],
                        }}
                      />
                      <Typography>{t(`ads.details.applyTab.history.status.${row.callStatus}`)}</Typography>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ minWidth: "250px" }}>
                    {row.callStatus === TwilioCallStatusEnum.COMPLETED ? (
                      <Button sx={{ gap: "8px" }} variant="text" onClick={() => downloadCall(row)}>
                        <img src="/images/download_report.svg" alt="download report" />
                        <Typography variant="caption" color={Colors.primary}>
                          {t("ads.details.applyTab.history.downloadReport")}
                        </Typography>
                      </Button>
                    ) : null}
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={{ minWidth: "170px" }}>
                    <Button
                      sx={{
                        gap: 1,
                        backgroundColor: alpha(Colors.twilioCallsStatus[row.callStatus], 0.3),
                        "&:hover": {
                          backgroundColor: alpha(Colors.twilioCallsStatus[row.callStatus], 0.5),
                        },
                        padding: "7px 8px",
                      }}
                      variant="text"
                      onClick={() => {
                        if (
                          row.callStatus === TwilioCallStatusEnum.COMPLETED ||
                          row.callStatus === TwilioCallStatusEnum.IN_PROGRESS ||
                          row.callStatus === TwilioCallStatusEnum.QUEUED ||
                          row.callStatus === TwilioCallStatusEnum.RINGING
                        ) {
                          setOpenCallChat(row);
                        } else if (!loadingCall) {
                          setOpenCallConfirmation(row);
                        }
                      }}>
                      <img src="/images/chatBot.svg" alt="chat" />
                      <Typography variant="caption" color={Colors.grey}>
                        {row.callStatus === TwilioCallStatusEnum.COMPLETED
                          ? t("ads.details.applyTab.history.report")
                          : row.callStatus === TwilioCallStatusEnum.IN_PROGRESS ||
                            row.callStatus === TwilioCallStatusEnum.RINGING ||
                            row.callStatus === TwilioCallStatusEnum.QUEUED
                          ? t("ads.details.applyTab.history.viewChat")
                          : t("ads.details.applyTab.history.callBack")}
                      </Typography>
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {openCallChat && <CallChatModal application={openCallChat.application} twilioAiCall={openCallChat} handleClose={() => setOpenCallChat(null)} />}
      {openCallConfirmation && (
        <CallConfirmationModal
          recontact={
            openCallConfirmation.callStatus === TwilioCallStatusEnum.NO_ANSWER ||
            openCallConfirmation.callStatus === TwilioCallStatusEnum.FAILED ||
            openCallConfirmation.callStatus === TwilioCallStatusEnum.CANCELED ||
            openCallConfirmation.callStatus === TwilioCallStatusEnum.BUSY
          }
          handleConfirmCall={() => handleConfirmCall(openCallConfirmation.application)}
          handleClose={() => setOpenCallConfirmation(null)}
        />
      )}
    </Stack>
  );
};

export default AdCallHistory;
