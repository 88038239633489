import { useObservable } from "@ngneat/react-rxjs";
import { alpha, CardActionArea, Grid, Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { jobBoardsQuery, jobBoardsService } from "@store/jobBoards";
import { BoostCreditsModel } from "@store/common/boostCredits.model";

import { Colors } from "@constants/colors.constant";

const SequenceCardStyled = styled(Stack)(
  (props) => `
  box-shadow: 0px 0px 5px ${alpha(Colors.marine, 0.05)};
  border-radius: 20px;
  height: 100%;
  & > *:first-of-type {
    align-items: flex-start;
    background-color: ${Colors.white};
    border-radius: 15px;
    box-shadow: ${props["aria-selected"] ? `inset 0px 0px 0px 1px ${Colors.primary},` : ""} 0px 0px 20px rgba(34,34,64, 0.05);
    height: 100%;
    justify-content: flex-start;
    padding: 10px 10px 20px 10px;
    &:not(.MuiCardActionArea-focusHighlight):hover {
      background-color: ${alpha(Colors.primary, 0.1)};
    }
  };
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    };
  };
`
);

const CreditBadgeStyled = styled(Stack)`
  background-color: ${alpha(Colors.primary, 0.1)};
  height: 22px;
  width: fit-content;
  border-radius: 4px;
`;

interface SequencesListProps {
  boostCredits?: BoostCreditsModel[];
}

const SequencesList = (props: SequencesListProps) => {
  const { boostCredits = [] } = props;

  const { t } = useTranslation();

  const [filters] = useObservable(jobBoardsQuery.filters$);

  const [sequences] = useObservable(jobBoardsQuery.sequences$);

  return (
    <Stack minHeight="100%" alignItems="center">
      <Grid container spacing={3}>
        {sequences
          .filter((s) => !filters?.search || s.name.toLowerCase().includes(filters.search.toLowerCase()))
          .sort((a, b) => {
            const boostCreditA = boostCredits.find((x) => x.boostItemId === a.id);
            if (boostCreditA) return -1;
            const boostCreditB = boostCredits.find((x) => x.boostItemId === b.id);
            if (boostCreditB) return 1;
            const typeDiff = a.name.localeCompare(b.name);
            if (typeDiff !== 0) return typeDiff;
            return b.price - a.price;
          })
          .map((sequence) => {
            const boostCredit = boostCredits.find((x) => x.boostItemId === sequence.id);
            const disabledAction = sequence.price <= 0 || (boostCredit && boostCredit.credits - boostCredit.creditsWaitingForValidation <= 0);

            return (
              <Grid item xs={12} sm={6} key={sequence.id}>
                <SequenceCardStyled aria-selected={sequence.selected}>
                  <Stack
                    spacing={2}
                    disableRipple={disabledAction}
                    disableTouchRipple={disabledAction}
                    sx={{
                      cursor: disabledAction ? "default" : undefined,
                      opacity: disabledAction ? 0.35 : 1,
                    }}
                    onClick={() => {
                      if (!disabledAction) jobBoardsService.selectJobBoardOrSequenceOrBoostAction(sequence.id);
                    }}
                    component={CardActionArea}>
                    <Stack
                      bgcolor={Colors.lightpurple}
                      px="13px"
                      py="25px"
                      borderRadius="13px"
                      justifyContent="center"
                      height="170px"
                      width="100%"
                      spacing={2}>
                      <Stack direction="row" justifyContent="center">
                        <img src={sequence.picture} />
                      </Stack>
                    </Stack>
                    <Stack>
                      <Stack direction="row" alignItems="flex-end" justifyContent="space-between" mb="6px">
                        <Typography fontSize="14px" fontWeight={500} color={Colors.secondary}>
                          {sequence.name}
                        </Typography>
                        {boostCredit && (
                          <CreditBadgeStyled alignItems="center" justifyContent="center">
                            <Typography mx="10px" color={Colors.primary} fontWeight={500} fontSize={11}>
                              {t("boostRequest.selection.myself.credits.amount", {
                                count: boostCredit.credits - boostCredit.creditsWaitingForValidation,
                              })}
                            </Typography>
                          </CreditBadgeStyled>
                        )}
                      </Stack>
                      <Typography fontSize={14} fontWeight="300" lineHeight={1.25} color={Colors.boostCard.secondaryText}>
                        {sequence.information}
                      </Typography>
                    </Stack>
                  </Stack>
                </SequenceCardStyled>
              </Grid>
            );
          })}
      </Grid>
    </Stack>
  );
};

export default SequencesList;
