import { OptionsObject, VariantType } from "notistack";

import I18n from "@utils/i18n.utils";

export const errorTexts: { [key: string]: string } = {
  WRONG_CREDENTIALS: I18n.t("errors.wrongCredentials"),
  ERROR_PARSING_XML: I18n.t("errors.wrongXMLFile"),
  NOT_ENOUGH_CREDIT: I18n.t("errors.noCredits"),
  "USER_DOESN'T_HAVE_AFFILIATES": I18n.t("errors.noAffiliates"),
  BOOST_ALREADY_EXIST_FOR_ANNOUNCEMENT: I18n.t("errors.boostExists"),
  CLIENT_BUDGET_LIMITATION_REACHED: I18n.t("errors.budgetLimitationReached"),
  WRONG_OLD_PASSWORD: I18n.t("errors.wrongPassword"),
  ANNOUNCEMENT_CREATED_LESS_THAN_10_DAYS: I18n.t("errors.adCreatedLessThan10Days"),
  BOOST_ALREADY_REQUESTED_LESS_THAN_30_DAYS: I18n.t("errors.boostRequestedLessThan30Days"),
  CLIENT_HAS_NO_CONTACTS: I18n.t("errors.clientHasNoContacts"),
  USER_EMAIL_ALREADY_EXIST: I18n.t("errors.emailAlreadyExist"),
  NO_LINKEDIN_USER_FOUND: I18n.t("errors.linkedinNotSynced"),
  USER_NOT_SYNC_WITH_LINKEDIN: I18n.t("errors.linkedinNotSynced"),
  USER_SYNCED_BUT_NOT_CONNECTED_WITH_LINKEDIN: I18n.t("errors.syncedButNotConnected"),
  TOKEN_EXPIRED: I18n.t("errors.tokenExpired"),
  AFFILIATE_LINKED_TO_CLIENT_EXIST: I18n.t("errors.affiliateExists"),
  FILE_TOO_LARGE: I18n.t("errors.fileTooLargeGeneric"),
  PAYMENT_FAILED: I18n.t("errors.paymentFailed"),
  STRIPE_NOT_LOADED: I18n.t("errors.stripeNotLoaded"),
  INVALID_SESSION: I18n.t("errors.invalidSession"),
  CANNOT_DELETE_CREDITS_WAITING_FOR_VALIDATION: I18n.t("errors.cannotDeleteCreditsWaitingForValidation"),
  CANNOT_UPDATE_CREDITS_WAITING_FOR_VALIDATION: I18n.t("errors.cannotUpdateCreditsWaitingForValidation"),
  INVALID_COUPON: I18n.t('errors.invalidCoupon'),
  NO_APPLICATIONS_FOR_PLAN_MEDIA: I18n.t('errors.noApplicationsForPlanMedia'),
  COULD_NOT_EXTRACT_DATA_FROM_PLAN_MEDIA_FILE: I18n.t('errors.couldNotExtractDataFromPlanMediaFile'),
};

class SnackError extends Error {
  private readonly text: string;
  private readonly options: OptionsObject;

  constructor(errorMessage: string, variant: VariantType, customMessage?: boolean, ...params: any[]) {
    super(...params);

    this.name = "SnackError";
    this.text = customMessage ? errorMessage : errorTexts[errorMessage] || I18n.t("errors.global");
    this.options = { variant };
  }
}

export default SnackError;
