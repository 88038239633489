import ApplicationCard from "@components/card/Application.card";
import { sessionQuery } from "@store/session";
import { Box, Button, Checkbox, Grow, IconButton, Menu, MenuItem, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AdDetailsApplicationsList, adDetailsQuery, adDetailsService } from "@store/ads/details";

import { Application, ApplicationsListEnum, ApplicationsListEnumFunctions } from "@store/applications/applications.model";
import { useNavigate } from "react-router-dom";
import React, { ChangeEvent, CSSProperties, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import UploadCvModal from "./UploadCv.modal";
import { Colors } from "@constants/colors.constant";
import { useTranslation } from "react-i18next";
import ConfirmModal from "@components/modal/Confirm.modal";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import { forkJoin } from "rxjs";
import { UserRoleEnum } from "@store/users";
import { subscriptionsQuery } from "@store/subscriptions";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { areEqual, VariableSizeList } from "react-window";
import ModalComponent from "@components/modal/Modal.component";
import { twilioAiCallsService } from "@store/twilioAiCalls";
import CallChatModal from "./applicationDetails/CallChat.modal";
import useWindowHeight from "@utils/hooks/useWindowHeight";

export const ITEM_HEIGHT = 153.5;
export const ITEM_HEIGHT_WITH_AI_ICONS = 171.5;
export const ITEM_HEIGHT_WITH_HRFLOW = 217.5;
export const ITEM_HEIGHT_WITH_HRFLOW_AND_AI_ICONS = 222.5;
export const ITEM_GAP = 30;
export const ITEM_WIDTH = 260;
export const AI_ICONS_PADDING = 40;

interface AdsApplicationContainerProps {
  columnName: string;
  disableDrag?: boolean;
  inAnimationApplications?: string[];
  items: AdDetailsApplicationsList;
  activeIds: string[] | null;
  adId: string | undefined;
  setCheckedApplications: (value: React.SetStateAction<{ columnName: string; id: string }[]>) => void;
  checkedApplications: { columnName: string; id: string }[];
  applicationCategories: ApplicationsListEnum[];
  handleEmailCandidate: Dispatch<SetStateAction<Application | false>>;
}

const AdsApplicationContainer = (props: AdsApplicationContainerProps) => {
  const {
    applicationCategories,
    columnName,
    disableDrag,
    inAnimationApplications,
    items,
    activeIds,
    adId,
    setCheckedApplications,
    checkedApplications,
    handleEmailCandidate,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const menuPopupMoveCv = usePopupState({ variant: "popover", popupId: "application-move" });

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openConfirmDeleteCvs, setOpenConfirmDeleteCvs] = useState(false);
  const [openCallConfirmation, setOpenCallConfirmation] = useState(false);
  const [openCallChat, setOpenCallChat] = useState<Application | null>(null);
  const [applicationChatChosen, setApplicationChatChosen] = useState<Application | null>(null);
  const [applicationLoadingCalls, setApplicationLoadingCalls] = useState<string[]>([]);

  const { height: windowHeight } = useWindowHeight();

  const variableSizeListRef = React.useRef<VariableSizeList>(null);

  useEffect(() => {
    if (variableSizeListRef.current) {
      variableSizeListRef.current.resetAfterIndex(0, true);
    }
  }, [applicationCategories]);

  useEffect(() => {
    if (openCallChat) {
      const application = items.applications.find((app) => app.id === openCallChat.id);
      if (application) {
        setApplicationChatChosen(application);
      }
    } else {
      setApplicationChatChosen(null);
    }
  }, [items.applications, openCallChat]);

  const thisColumnCheckedApplications = useMemo(
    () => checkedApplications.filter((c) => c.columnName === columnName),
    [checkedApplications, columnName]
  );
  const checkedApplicationsWithPhoneNumber = useMemo(
    () =>
      items.applications.filter((application) => {
        const isChecked = thisColumnCheckedApplications.some((checkedApp) => checkedApp.id === application.id);
        return isChecked && application.phoneNumber !== null;
      }),
    [thisColumnCheckedApplications, items.applications]
  );

  const handleCheckApplication = (applicationId: string, checked: boolean) => {
    if (checked) {
      setCheckedApplications([...checkedApplications, { columnName, id: applicationId }]);
    } else {
      setCheckedApplications(checkedApplications.filter((x) => x.id !== applicationId));
    }
  };

  const handleCheckAll = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCheckedApplications((prev) => (checked ? items.applications.map((x) => ({ columnName, id: x.id })) : []));
  };

  const handleDeleteCVs = () => {
    if (!thisColumnCheckedApplications.length) return;
    adDetailsService.deleteCvs(thisColumnCheckedApplications.map((x) => x.id)).subscribe({
      next: () => {
        setCheckedApplications((prev) => prev.filter((x) => !thisColumnCheckedApplications.some((y) => y.id === x.id)));
        enqueueSnackbar(t(`applications.deleteSuccess`), { variant: "success" });
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const handleDownloadCvs = () => {
    if (!thisColumnCheckedApplications.length || !adId) return;
    if (thisColumnCheckedApplications.length > 1) {
      adDetailsService
        .downloadCVsHasZip(
          adId,
          thisColumnCheckedApplications.filter((x) => x.columnName === columnName).map((x) => x.id),
          adDetailsQuery.adName ?? adId
        )
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      adDetailsService.downloadCVOnly(adId, thisColumnCheckedApplications[0].id).subscribe();
    }
  };

  const handleMoveCvs = (category: ApplicationsListEnum) => {
    if (!thisColumnCheckedApplications.length || !adId) return;
    forkJoin(thisColumnCheckedApplications.map((x) => adDetailsService.editAdApplicationCategory(x.id, category))).subscribe({
      next: () => {
        setCheckedApplications((prev) => prev.filter((x) => !thisColumnCheckedApplications.some((y) => y.id === x.id)));
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const handleConfirmCalls = (applications: Application[]) => {
    setOpenCallConfirmation(false);
    setApplicationLoadingCalls(applications.map((app) => app.id));
    applications.forEach((application) => {
      twilioAiCallsService.makeAiCall(application).subscribe({
        next: (twilioAiCall) => {
          twilioAiCallsService.upsertEntities(twilioAiCall);
          twilioAiCallsService.handleSocketListeners([twilioAiCall]);
          setApplicationLoadingCalls((prev) => prev.filter((id) => id !== application.id));
        },
        error: (err) => {
          setApplicationLoadingCalls((prev) => prev.filter((id) => id !== application.id));
          enqueueSnackbar(err.text, err.options);
        },
        complete: () => {
          setApplicationLoadingCalls((prev) => prev.filter((id) => id !== application.id));
        },
      });
    });
  };

  const sortedApplications = items.applications.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const getItemHeight = (index: number) => {
    const JOBBOARD_LINE_HEIGHT = 17.5;
    // const AVATAR_LINE_HEIGHT = 70;
    const AI_O_LINE_HEIGHT = 20;

    let height = ITEM_HEIGHT_WITH_HRFLOW + ITEM_GAP;
    const item = sortedApplications[index];
    if (!item?.candidate?.fullName && !item?.candidate?.city) {
      height = ITEM_HEIGHT + ITEM_GAP;
      if (item?.twilioAiCall?.callAnalysis || item?.aiEvaluation) height = ITEM_HEIGHT_WITH_AI_ICONS + ITEM_GAP;
    } else {
      if (item?.twilioAiCall?.callAnalysis || item?.aiEvaluation) height = ITEM_HEIGHT_WITH_HRFLOW_AND_AI_ICONS + ITEM_GAP;
    }

    if (!item?.jobboard) height -= JOBBOARD_LINE_HEIGHT;
    if (item?.aioApplicationId) height += AI_O_LINE_HEIGHT;

    return height;
  };

  interface DraggableItemProps {
    data: Application[];
    index: number;
    style: CSSProperties;
  }

  const DraggableItem = React.memo(function Row(props: DraggableItemProps) {
    const { data: items, index, style } = props;
    const item = items[index];

    if (!item) {
      return null;
    }

    return (
      <Draggable key={item.id} isDragDisabled={!!disableDrag} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <ApplicationCard
            isDragged={activeIds?.includes(item.id)}
            key={item.id}
            applyInfos={item}
            small
            handleClick={() => {
              navigate(`${item.id}`);
            }}
            handleCheck={(e, checked) => {
              handleCheckApplication(item.id, checked);
            }}
            setCheckedApplications={setCheckedApplications}
            checked={thisColumnCheckedApplications.some((x) => x.id === item.id)}
            applicationCategories={applicationCategories}
            categoryName={columnName}
            handleEmailCandidate={handleEmailCandidate}
            virtualizedStyle={style}
            provided={provided}
            virtualized
            isInAiSortAnimation={inAnimationApplications?.some((x) => x === item.id)}
            setOpenCallChat={setOpenCallChat}
            applicationLoadingCalls={applicationLoadingCalls}
          />
        )}
      </Draggable>
    );
  }, areEqual);

  return (
    <Droppable
      droppableId={columnName}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => {
        const item = sortedApplications[rubric.source.index];

        return (
          <ApplicationCard
            isDragged={true}
            applyInfos={item}
            small
            provided={provided}
            multipleDragNumber={(activeIds?.length && activeIds?.length) ?? 1}
          />
        );
      }}>
      {(provided, snapshot) => {
        const itemCount = snapshot.isUsingPlaceholder ? sortedApplications.length + 1 : sortedApplications.length;

        return (
          <>
            <Box
              id={columnName}
              height="100%"
              minWidth={breakpointDownSM ? "280px" : "310px"}
              mr={breakpointDownSM ? "20px" : "40px"}
              position="relative">
              <Grow in={!!thisColumnCheckedApplications.length} mountOnEnter unmountOnExit>
                <Stack
                  direction="column"
                  width="100%"
                  justifyContent="center"
                  spacing="11px"
                  py="20px"
                  sx={{ position: "absolute", bottom: 0, zIndex: 10, borderRadius: "0 0 15px 15px" }}
                  bgcolor={Colors.greyCardBg}>
                  <Typography align="center" fontSize="14px" fontWeight={300}>
                    {t("ads.details.applyTab.applications_selected", { count: thisColumnCheckedApplications.length })}
                  </Typography>
                  <Stack direction="row" width="100%" justifyContent="center" spacing="8px">
                    <Tooltip
                      title={
                        <Typography fontSize="12px" fontWeight={300}>
                          {t("global.delete")}
                        </Typography>
                      }
                      placement="bottom"
                      PopperProps={{ "aria-label": "variant-primary" }}
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -7],
                              },
                            },
                          ],
                        },
                      }}>
                      <Button
                        style={{ minWidth: "40px", borderRadius: "15px", padding: "10px" }}
                        variant="contained"
                        onClick={() => setOpenConfirmDeleteCvs(true)}>
                        <img height="15px" alt="delete" src="/images/icon_delete_white_small.svg" />
                      </Button>
                    </Tooltip>
                    {(sessionQuery.user.role === UserRoleEnum.ADMIN || subscriptionsQuery.sessionUserSubscriptionOngoing) && (
                      <Tooltip
                        title={
                          <Typography fontSize="12px" fontWeight={300}>
                            {t("ads.details.applyTab.download")}
                          </Typography>
                        }
                        placement="bottom"
                        PopperProps={{ "aria-label": "variant-primary" }}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -7],
                                },
                              },
                            ],
                          },
                        }}>
                        <Button style={{ minWidth: "40px", borderRadius: "15px", padding: "10px" }} variant="contained" onClick={handleDownloadCvs}>
                          <img height="15px" alt="download" src="/images/icon_download_file_white.svg" />
                        </Button>
                      </Tooltip>
                    )}
                    <Box {...bindTrigger(menuPopupMoveCv)}>
                      <Tooltip
                        title={
                          <Typography fontSize="12px" fontWeight={300}>
                            {t("ads.details.applyTab.moveTo")}
                          </Typography>
                        }
                        placement="bottom"
                        PopperProps={{ "aria-label": "variant-primary" }}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -7],
                                },
                              },
                            ],
                          },
                        }}>
                        <Button style={{ minWidth: "40px", borderRadius: "15px", padding: "10px" }} variant="contained">
                          <img height="15px" alt="move" src="/images/icon_move_cv.svg" />
                        </Button>
                      </Tooltip>
                    </Box>
                    {/* {columnName !== ApplicationsListEnum.TO_BE_PROCESSED && (
                      <Tooltip
                        title={
                          <Typography fontSize="12px" fontWeight={300}>
                            {t("ads.details.applyTab.call")}
                          </Typography>
                        }
                        placement="bottom"
                        PopperProps={{ "aria-label": "variant-primary" }}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -7],
                                },
                              },
                            ],
                          },
                        }}>
                        <Button
                          style={{ minWidth: "40px", borderRadius: "15px", padding: "10px" }}
                          variant="contained"
                          onClick={() => {
                            setOpenCallConfirmation(true);
                          }}>
                          <img height="15px" alt="phone" src="/images/icon_phone_white.svg" />
                        </Button>
                      </Tooltip>
                    )} */}
                  </Stack>
                  <Menu
                    {...bindMenu(menuPopupMoveCv)}
                    aria-label="user-menu"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    transformOrigin={{ vertical: "bottom", horizontal: "center" }}>
                    <MenuItem disabled>{t("ads.details.applyTab.moveTo")}</MenuItem>
                    {applicationCategories
                      .filter((category) => category !== columnName)
                      .map((category) => (
                        <MenuItem
                          key={category}
                          onClick={(evt) => {
                            evt.stopPropagation();
                            handleMoveCvs(category);
                            menuPopupMoveCv.close();
                          }}>
                          {ApplicationsListEnumFunctions.label(category)}
                        </MenuItem>
                      ))}
                  </Menu>
                </Stack>
              </Grow>

              <Stack
                bgcolor={Colors.greyCardBg}
                p={
                  breakpointDownSM
                    ? `15px 10px ${!!thisColumnCheckedApplications.length ? "10px" : "0"} 20px`
                    : `25px 20px ${!!thisColumnCheckedApplications.length ? "10px" : "0"} 30px`
                }
                style={{ boxShadow: snapshot.isDraggingOver ? `0px 0px 0px 1px ${Colors.primary}` : undefined }}
                borderRadius="15px"
                height="100%"
                alignItems="center"
                overflow="hidden">
                <Stack direction="row" justifyContent="space-between" alignItems="center" alignSelf="flex-start" width="100%">
                  <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} overflow="hidden">
                    {!!items.applications.length && (
                      <Checkbox
                        id={"column-checkbox" + columnName}
                        size="small"
                        onChange={handleCheckAll}
                        checked={thisColumnCheckedApplications.length === items.applications.length}
                        onClick={(e) => e.stopPropagation()}
                      />
                    )}
                    <Typography fontSize="16px" fontWeight="700">
                      {ApplicationsListEnumFunctions.label(items.name)}
                    </Typography>
                    <Typography fontSize="16px" fontWeight="700" color={Colors.secondaryText}>
                      {items.applications.length}
                    </Typography>
                  </Stack>
                  {sessionQuery.role === UserRoleEnum.ADMIN && items.name === ApplicationsListEnum.TO_BE_PROCESSED && (
                    <IconButton size="small" component="label" sx={{ height: 25, width: 25 }} onClick={() => setOpenUploadModal(true)}>
                      <img alt="plus" src="/images/button_add_more.svg" />
                    </IconButton>
                  )}
                </Stack>
                <Box
                  overflow="hidden"
                  flex={1}
                  pt={breakpointDownSM ? "10px" : "20px"}
                  pb={breakpointDownSM ? "20px" : "30px"}
                  pr={breakpointDownSM ? "0px" : "0px"}>
                  <Stack spacing={breakpointDownSM ? 1 : 3}>
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <VariableSizeList
                        height={windowHeight - 470 - (!!thisColumnCheckedApplications.length ? 102 : 0)}
                        itemCount={itemCount}
                        itemSize={getItemHeight}
                        width={ITEM_WIDTH}
                        outerRef={provided.innerRef}
                        ref={variableSizeListRef}
                        itemData={sortedApplications}
                        style={{ position: "relative" }}>
                        {DraggableItem}
                      </VariableSizeList>
                    </div>
                  </Stack>
                </Box>
              </Stack>
            </Box>
            {openUploadModal && <UploadCvModal handleClose={() => setOpenUploadModal(false)} itemsName={items.name} adId={adId} />}
            {openConfirmDeleteCvs && (
              <ConfirmModal
                handleClose={() => setOpenConfirmDeleteCvs(false)}
                handleConfirm={handleDeleteCVs}
                modalTitle={t("ads.details.applyTab.confirmDeleteTitle")}
                confirmMsg={t("ads.details.applyTab.confirmDelete")}
              />
            )}
            {openCallConfirmation && (
              <ModalComponent
                maxWidth={breakpointDownSM ? "xs" : false}
                fullWidth={breakpointDownSM}
                handleClose={() => setOpenCallConfirmation(false)}
                content={
                  <Stack width={breakpointDownSM ? "auto" : "518px"} spacing={3} alignItems={"center"}>
                    <img width="80px" src="/images/img_ia_phone.png" alt="phone ia" />
                    <Typography fontSize={"20px"} fontWeight={"700"} textAlign="center" alignSelf="center">
                      {t("ads.details.applyTab.aiCalls.toBeCalledByAi")}
                    </Typography>
                    <Typography fontSize={"14px"} fontWeight={"400"} textAlign="center" alignSelf="center" color={Colors.lightGrey}>
                      {t("ads.details.applyTab.aiCalls.confirmCall", { count: checkedApplicationsWithPhoneNumber.length })}
                    </Typography>
                    {thisColumnCheckedApplications.length === 1 && checkedApplicationsWithPhoneNumber.length === 0 ? (
                      <Stack direction={"row"} alignItems={"start"} justifyContent={"center"}>
                        <img src="/images/icon_informations_grey.svg" style={{ marginTop: "3px" }} alt="informations" />
                        <Typography maxWidth={"85%"} fontSize={"14px"} fontWeight={"200"} textAlign="center" color={Colors.lightGrey}>
                          {t("ads.details.applyTab.aiCalls.oneSelectedAndCannotCall")}
                        </Typography>
                      </Stack>
                    ) : thisColumnCheckedApplications.length - checkedApplicationsWithPhoneNumber.length > 0 ? (
                      <Stack direction={"row"} alignItems={"start"} justifyContent={"center"}>
                        <img src="/images/icon_informations_grey.svg" style={{ marginTop: "3px" }} alt="informations" />
                        <Typography maxWidth={"85%"} fontSize={"14px"} fontWeight={"200"} textAlign="center" color={Colors.lightGrey}>
                          {t("ads.details.applyTab.aiCalls.confirmCallInfoPartOne", {
                            count: thisColumnCheckedApplications.length,
                          }) +
                            t("ads.details.applyTab.aiCalls.confirmCallInfoPartTwo", {
                              count: thisColumnCheckedApplications.length - checkedApplicationsWithPhoneNumber.length,
                            })}
                        </Typography>
                      </Stack>
                    ) : (
                      <></>
                    )}
                  </Stack>
                }
                actions={
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={checkedApplicationsWithPhoneNumber.length === 0}
                    onClick={() => handleConfirmCalls(checkedApplicationsWithPhoneNumber)}>
                    {t("global.confirm")}
                  </Button>
                }
              />
            )}
            {applicationChatChosen?.twilioAiCall && (
              <CallChatModal
                application={applicationChatChosen}
                twilioAiCall={applicationChatChosen.twilioAiCall}
                handleClose={() => setOpenCallChat(null)}
              />
            )}
          </>
        );
      }}
    </Droppable>
  );
};

export default AdsApplicationContainer;
