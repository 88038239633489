import { Application } from "@store/applications";
import i18n from "@utils/i18n.utils";

export enum TwilioCallsStatusParentCategoryEnum {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum TwilioCallStatusEnum {
  QUEUED = "queued",
  RINGING = "ringing",
  IN_PROGRESS = "in-progress",
  COMPLETED = "completed",
  BUSY = "busy",
  FAILED = "failed",
  NO_ANSWER = "no-answer",
  CANCELED = "canceled",
}

export const twilioCallStatusItems = [
  { value: TwilioCallStatusEnum.COMPLETED, label: i18n.t(`ads.details.applyTab.history.status.${TwilioCallStatusEnum.COMPLETED}`) },
  { value: TwilioCallStatusEnum.IN_PROGRESS, label: i18n.t(`ads.details.applyTab.history.status.${TwilioCallStatusEnum.IN_PROGRESS}`) },
  { value: TwilioCallStatusEnum.FAILED, label: i18n.t(`ads.details.applyTab.history.status.${TwilioCallStatusEnum.FAILED}`) },
  { value: TwilioCallStatusEnum.NO_ANSWER, label: i18n.t(`ads.details.applyTab.history.status.${TwilioCallStatusEnum.NO_ANSWER}`) },
  { value: TwilioCallStatusEnum.RINGING, label: i18n.t(`ads.details.applyTab.history.status.${TwilioCallStatusEnum.RINGING}`) },
  { value: TwilioCallStatusEnum.QUEUED, label: i18n.t(`ads.details.applyTab.history.status.${TwilioCallStatusEnum.QUEUED}`) },
  { value: TwilioCallStatusEnum.CANCELED, label: i18n.t(`ads.details.applyTab.history.status.${TwilioCallStatusEnum.CANCELED}`) },
];

export const TwilioCallsStatusParentCategory = [
  {
    value: TwilioCallsStatusParentCategoryEnum.IN_PROGRESS,
    status: [TwilioCallStatusEnum.IN_PROGRESS, TwilioCallStatusEnum.QUEUED, TwilioCallStatusEnum.RINGING],
  },
  {
    value: TwilioCallsStatusParentCategoryEnum.COMPLETED,
    status: [TwilioCallStatusEnum.COMPLETED],
  },
  {
    value: TwilioCallsStatusParentCategoryEnum.FAILED,
    status: [TwilioCallStatusEnum.FAILED, TwilioCallStatusEnum.BUSY, TwilioCallStatusEnum.NO_ANSWER, TwilioCallStatusEnum.CANCELED],
  },
];

export function getParentCategoryByStatus(status: TwilioCallStatusEnum): TwilioCallsStatusParentCategoryEnum {
  const subCategory = TwilioCallsStatusParentCategory.find((subCategory) => subCategory.status.includes(status));
  return subCategory?.value || TwilioCallsStatusParentCategoryEnum.FAILED;
}

export enum TwilioCallParticipantTypeEnum {
  AI = "AI",
  RECIPIENT = "RECIPIENT",
}

export enum TwilioWebsocketMessageTypeEnum {
  CALL_STATUS_UPDATE = "CALL_STATUS_UPDATE",
  CONVERSATION_UPDATE = "CONVERSATION_UPDATE",
  RECORDING_COMPLETE = "RECORDING_COMPLETE",
  CALL_ANALYSIS = "CALL_ANALYSIS",
}

export enum TwilioWebsocketErrorMessageEnum {
  TWILIO_AI_CALL_NOT_FOUND = "TWILIO_AI_CALL_NOT_FOUND",
}

export interface TwilioCallConversationMessage {
  participantType: TwilioCallParticipantTypeEnum;
  content: string;
}

export interface TwilioWebsocketMessageData {
  callStatus?: TwilioCallStatusEnum;
  errorMessage?: TwilioWebsocketErrorMessageEnum;
  conversationMessages?: TwilioCallConversationMessage[];
  recordingSid?: string;
  callAnalysis?: CallAnalysis;
}

export interface TwilioWebsocketMessage {
  callSid: string;
  type: TwilioWebsocketMessageTypeEnum;
  data: TwilioWebsocketMessageData;
}

export interface CallAnalysis {
  mark: number;
  analysis: string;
}

export interface TwilioAiCall {
  id: string;
  callSid: string;
  accountSid: string;
  to: string;
  from: string;
  callStatus: TwilioCallStatusEnum;
  conversation: TwilioCallConversationMessage[];
  application: Application;
  recordingSid?: string;
  createdAt: Date;
  completedAt?: Date;
  callAnalysis?: CallAnalysis;
}

export const finishedTwilioAiCallStatus = [
  TwilioCallStatusEnum.COMPLETED,
  TwilioCallStatusEnum.FAILED,
  TwilioCallStatusEnum.NO_ANSWER,
  TwilioCallStatusEnum.CANCELED,
];
